import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MessageSenders from 'src/Components/Common/EntitiesView/MessageSenders'
import MetaBar from 'src/Components/Common/MetaBar'
import { Select } from 'src/Components/Common/Select'
import UiContent from 'src/Components/Common/UiContent'
import { LoanRelated } from './Related'
import { toast } from 'react-toastify'
import WebPhone from 'src/Components/WebPhone/ui/WebPhoneUI'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Client, { LIST_LIMIT } from 'src/client'
import moment from 'moment'
import { noop, omit } from 'src/utils'

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'

import {
  useGetExternalServicesQuery,
  useGetLoanPromoInfoQuery,
  useGetLoanPromotionQuery,
  useGetLoanPurchaserQuery,
  useGetLoanQuery,
  useSetLoanAsFraudulentMutation,
  useSetLoanPromotionAsActiveMutation
} from 'src/services/loans'
import { CallResultModal } from 'src/Components/Common/EntitiesView/CallResult/modals/CallResultModal'
import EntityInfo from 'src/Components/Common/EntityInfo'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { useSelector } from 'src/store'
import { useGetClientTagsQuery, useGetLoanTagsQuery } from 'src/services/tags'
import { useGetProfileQuery } from 'src/services/profiles'
import { useGetPromotionQuery } from 'src/services/promotions'
import Loader from 'src/Components/Common/Loader'
import FreezeAccrual from 'src/Components/Common/EntitiesView/FreezeAccrual'
import ConsentAcceptante from 'src/Components/Common/EntitiesView/ConsentAcceptante'
import { AskAboutAction } from 'src/Components/Common/AskAboutAction'
import { useGetCollectorsQuery } from 'src/services/collectors'
import ReloadContract from 'src/Components/Common/EntitiesView/ReloadConstract'
import InfoServices from 'src/Components/Common/EntitiesView/InfoServices'
import { ConfirmButton } from 'src/Components/Common/ConfirmButton'
import { useDisclosure } from 'src/utils/useDisclosure'
import { useModalDisclosureContext } from 'src/ModalDisclosureProvider'
import { LoanTableKPK } from 'src/Components/kpkEntity/LoanTableKPK'

const columnsDescription = [
  ['org_origin', 'interest_frozen_end_date'],
  ['org_owner', 'penalties_frozen_end_date'],
  ['org_collect', 'loan_close_date'],
  ['status', 'loan_repayment_date'],
  ['loan_issue_card', 'prolongation_count'],
  ['loan_issue_date', 'dpd'],

  ['term', 'activation_date'],
  ['amount_writeoff', 'expiration_date'],
  ['amount_debt', 'amount_to_return'],

  ['amount_principal_debt', 'interest_freezing'],
  ['amount_interest_debt', 'fines_freezing'],
  ['amount_insurance_debt', 'main_body_writeoff'],
  ['amount_juridical_debt', 'interest_writeoff'],
  ['amount_telemedicine_debt'],
  ['amount_penalty_debt', 'amount_interest_paid'],
  ['amount_premium_account_debt', 'amount_principal_paid'],

  ['amount_interest_accrued', 'amount_issuance_fee_paid'],
  ['amount_principal_accrued', 'amount_premium_account_paid'],
  ['amount_issuance_fee_accrued', 'amount_insurance_paid'],
  ['amount_premium_account_accrued', 'amount_juridical_paid'],
  ['amount_insurance_accrued', 'amount_telemedicine_paid'],
  ['amount_juridical_accrued', 'amount_penalty_paid'],
  ['amount_telemedicine_accrued', 'amount_ext_fee_paid'],
  ['amount_penalty_accrued', 'amount_repayment_fee_paid'],
  ['amount_ext_fee_accrued'],
  ['amount_repayment_fee_accrued'],

  ['interest', 'interest_rate_overdue'],
  ['amount_overpayment'],
  ['extension_available', 'amount_for_extension'],
  ['sale_date', 'company_short_name']
]

export const LoanView: FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const { data: purchaser, isSuccess: isGetPurchaserSuccess } =
    useGetLoanPurchaserQuery(searchParams.get('id')!, {
      skip: searchParams.get('id') == null
    })

  const {
    data: loan,
    error: listError,
    isLoading: loanLoading
  } = useGetLoanQuery(searchParams.get('id')!, {
    skip: searchParams.get('id') == null
  })

  const { data: externalServices } = useGetExternalServicesQuery(
    searchParams.get('id')!,
    {
      skip: searchParams.get('id') == null
    }
  )

  const { data: loanPromoInfo, isSuccess: isGetLoanPromoInfoSuccess } =
    useGetLoanPromoInfoQuery(searchParams.get('id')!, {
      skip: searchParams.get('id') == null
    })

  const roles = useSelector((state) => state.Login.userInfo?.roles)

  const { isOpen, open, close, toggle } = useDisclosure()
  const { data: profile } = useGetProfileQuery(loan?.profile_id!, {
    skip: loan?.profile_id == null
  })
  const [phone, setPhone] = useState('')

  const title = t('View loan')
  document.title = title

  if (!searchParams.get('id') || listError) {
    return listError ? (
      <ErrorPage backLink={'/loans'} title={title} error={listError} />
    ) : (
      <ErrorPage backLink={'/loans'} title={title} error='No loan id found' />
    )
  }

  if (!loan) {
    return <LoadingPage backLink={'/loans'} title={title} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
        <Card>
          <CardBody>
            <MetaBar
              editLink={
                loan
                  ? `/loan-edit?id=${loan.id}&clientId=${profile?.client.id}`
                  : null
              }
              backLink={'/loans'}
              entity={loan}
              createLink={undefined}
              buttons={undefined}
              disableBorder={undefined}
            />

            <LoanViewActionBar
              phonePrefix={loan?.phone_prefix}
              loanId={searchParams.get('id')}
              profileId={loan?.profile_id}
              requestId={loan?.request_id}
              loanConsent={loan?.consent_direct_debit}
              timezone={loan?.timezone}
              status={loan?.status}
            />

            {loan && loan?.product_type === 'loan_kpk' ? (
              <Row className='mt-3'>
                <LoanTableKPK
                  entity={{
                    ...loan,
                    ...loan.ext_details
                  }}
                />
              </Row>
            ) : (
              loanLoading && (
                <Row className='mt-3'>
                  <Loader />
                </Row>
              )
            )}

            <Row className='mt-3'>
              {loan &&
                isGetPurchaserSuccess &&
                isGetLoanPromoInfoSuccess &&
                externalServices && (
                  <EntityInfo
                    entity={{
                      ...loan,
                      ...purchaser,
                      ...omit(loanPromoInfo, ['id']),
                      externalServices
                    }}
                    entityId={loan.id}
                    entityType={Client.ENTITY_TYPE.LOANS}
                    columnsDescription={columnsDescription}
                  />
                )}
            </Row>

            {loan && (
              <Row className='mt-5'>
                <LoanRelated
                  loan={loan}
                  profile={profile}
                  open={open}
                  setPhone={setPhone}
                />
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
      {profile &&
        roles?.find(
          (role) => role === 'collector' || role === 'operatorBackOffice'
        ) && (
          <CallResultModal
            entity={{ ...loan, profile }}
            phone={phone}
            loan_id={loan.id}
            profile_id={profile.id}
            isOrder={searchParams.get('is_order') === 'true'}
            collectionQueueSeq={searchParams.get('collection_queue_id')}
            isOpen={isOpen}
            open={open}
            close={close}
            toggle={toggle}
          />
        )}
    </>
  )
}

const LoanViewActionBar: FC<{
  phonePrefix?: number
  loanId?: string | null
  profileId?: string
  requestId?: string
  loanConsent?: boolean | null
  timezone?: string | null
  status?: string | null
}> = ({
  phonePrefix,
  loanId,
  profileId,
  requestId,
  loanConsent,
  timezone,
  status
}) => {
  const { t } = useTranslation()

  const modalContext = useModalDisclosureContext()

  type userStageType = {
    name: string
    id: number
  }
  const navigate = useNavigate()

  const [options, setOptions] = useState([{ name: '-', value: '-' }])

  const [collectorToAssign, setCollectorToAssign] = useState('-')

  const [userStages, setUserStages] = useState<userStageType[]>([])

  const [selectUserStage, setSelectUserStage] = useState<
    userStageType | undefined
  >(userStages[0])

  const [assignSuccess, setAssignSuccess] = useState(false)

  const [assignError, setAssignError] = useState('')

  const [setLoanAsFraudulent, { isSuccess: markAsFraudulentSuccess }] =
    useSetLoanAsFraudulentMutation()

  const markAsFraudulent = () => {
    try {
      if (loanId) setLoanAsFraudulent(loanId)
    } finally {
      setShowMark(false)
    }
  }

  const [showMark, setShowMark] = useState(false)

  const toggle = useCallback(() => setShowMark((prev) => !prev), [])

  const { data: loanTags } = useGetLoanTagsQuery(loanId!, {
    skip: loanId == null
  })

  const { data: profile } = useGetProfileQuery(profileId!, {
    skip: profileId == null
  })

  const { data: clientTags } = useGetClientTagsQuery(profile?.client.id!, {
    skip: profile?.client.id == null
  })
  const [showCollectors, setShowCollectors] = useState(true)

  const { data: listCollectors } = useGetCollectorsQuery(
    { limit: LIST_LIMIT, offset: 0 },
    { skip: showCollectors }
  )

  useEffect(() => {
    if (listCollectors) {
      const selectOptions = [
        { name: '-', value: '-' },
        ...listCollectors!.map((user: any) => ({
          ...user,
          name: `${user.surname} ${user.name} ${user.midname}`,
          value: user.id
        }))
      ]
      selectOptions.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      )
      setOptions(selectOptions)
    } else {
      const selectOptions = [
        { name: '-', value: '-' },
        { name: t('Loading...'), value: '-' }
      ]
      setOptions(selectOptions)
    }
  }, [listCollectors, showCollectors, t])

  const redirectToApplication = () => {
    navigate(`/application-view?id=${requestId}`)
  }
  const handleOpenWebphoneClick = () =>
    modalContext
      .open((props) => (
        <WebPhone
          {...props}
          numberFromLoan={`${phonePrefix || ''}${profile?.phone}`}
        />
      ))
      .catch(noop)

  return (
    <>
      <Button
        disabled={showMark}
        color='danger'
        size='sm'
        style={{ marginTop: '10px', marginBottom: '5px' }}
        onClick={() => setShowMark(true)}
      >
        {t('Mark as fraudulent')}
      </Button>
      {requestId && (
        <Button
          size='sm'
          color='success'
          style={{ marginTop: '10px', marginBottom: '5px', marginLeft: '10px' }}
          onClick={redirectToApplication}
        >
          {t('look_at_application')}
        </Button>
      )}

      {loanId && (
        <>
          <ConsentAcceptante loanId={loanId} loanConsent={loanConsent} />
          <ReloadContract loanId={loanId} />
          <InfoServices loanId={loanId} active={status} />
        </>
      )}

      {showMark && (
        <AskAboutAction
          cancelAction={() => setShowMark(false)}
          sureAction={markAsFraudulent}
          isOpen={showMark}
          toggle={toggle}
          title={t('Mark as fraudulent')}
        />
      )}

      {markAsFraudulentSuccess && (
        <Alert color='success'>
          <strong> {t('Operation success')}</strong>
        </Alert>
      )}

      <div className='h-divider py-2'>
        <div
          className='gap-3'
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative'
          }}
        >
          <div className='d-flex gap-3'>
            <div className='d-flex flex-row gap-2'>
              <Button
                size='sm'
                disabled={collectorToAssign === '-'}
                onClick={() => {
                  setAssignError('')
                  setAssignSuccess(false)

                  // TODO: add services/supervisors.ts and useAssignCollectorMutation

                  Client.assign(
                    loanId,
                    collectorToAssign,
                    selectUserStage && selectUserStage.id
                  )
                    .then(() => {
                      setAssignSuccess(true)
                    })
                    .catch((err) => {
                      setAssignError(err)
                    })
                }}
              >
                {t('assign_to')}
              </Button>

              <Select
                options={options}
                onFocus={() => setShowCollectors(false)}
                onChange={(collector: any) => {
                  setCollectorToAssign(collector)
                  setAssignError('')
                  setAssignSuccess(false)
                  Client.getUserStagesCollector(collector).then(
                    (userStages) => {
                      setUserStages(userStages)
                      setSelectUserStage(userStages[0])
                    }
                  )
                }}
                value={collectorToAssign || '-'}
                size={undefined}
              />

              {userStages?.length > 1 && (
                <Select
                  options={userStages}
                  onFocus={undefined}
                  onChange={(collector: string) => {
                    setSelectUserStage(
                      userStages.find((item) => collector === item.name)
                    )
                  }}
                  size={undefined}
                  value={selectUserStage?.name}
                />
              )}
            </div>
            {assignError && (
              <small className='text-danger'>{assignError.toString()}</small>
            )}
            {assignSuccess && (
              <small className='text-success'>{t('saved')}</small>
            )}

            {loanId && profileId && <MessageSenders loan_id={loanId} />}

            <Button
              onClick={handleOpenWebphoneClick}
              disabled={!profile?.phone}
            >
              {t('Call')}
            </Button>

            {loanId && <ChangeLoanDiscountButton loanId={loanId} />}

            {loanId && <FreezeAccrual loanId={loanId} />}
          </div>
          <div
            className='bg-light rounded shadow border'
            style={{
              position: 'absolute',
              left: 950
            }}
          >
            <div className='d-flex flex-column h-100'>
              <div className='w-100 p-3'>
                <div>
                  <span className='fw-medium'>{t('Timezone')}</span>
                  <b> {timezone || '-'}</b>
                </div>
                <div>
                  <span> {t('Client time')}</span>
                  <b>
                    {' '}
                    {timezone
                      ? moment()
                          .utcOffset(Number(timezone?.slice(4)) * 60)
                          .format('HH:mm')
                      : '-'}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loanTags && loanTags.length > 0 && (
          <div className='d-flex gap-2 mb-3 mt-2'>
            {loanTags.map((item, index) => (
              <div
                style={{
                  display: 'flex',
                  border: '1px solid #405189',
                  borderRadius: '10px',
                  padding: '3px 10px',
                  alignItems: 'center'
                }}
                key={index}
              >
                {item.tag}
              </div>
            ))}
          </div>
        )}

        {clientTags && clientTags.length > 0 && (
          <div className='d-flex gap-2 mb-3 mt-2'>
            {clientTags.map((item) => (
              <div
                style={{
                  display: 'flex',
                  border: '1px solid #405189',
                  borderRadius: '10px',
                  padding: '3px 10px',
                  alignItems: 'center'
                }}
                key={item.tag_type_id}
              >
                {item.tag}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const ChangeLoanDiscountButton: FC<{ loanId: string }> = ({ loanId }) => {
  const { t } = useTranslation()

  const { data: loanPromotion } = useGetLoanPromotionQuery(loanId, {
    skip: loanId == null
  })

  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => setIsOpen((prev) => !prev), [])

  const handleSuccess = useCallback(() => {
    toggle()
    toast.success(t('Successfully activated loan promotion'))
  }, [t, toggle])

  const handleError = useCallback(() => {
    toggle()
    toast.error(t('Error activating loan promotion'))
  }, [t, toggle])

  return (
    <>
      <Button
        type='button'
        size='sm'
        onClick={toggle}
        disabled={!loanPromotion}
      >
        {t('Add promotion')}
      </Button>
      {loanPromotion && (
        <Modal isOpen={isOpen} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>{t('Add promotion')}</ModalHeader>
          <ModalBody>
            <LoanPromotionForm
              loanId={loanId}
              promotionId={loanPromotion.promotion_id}
              isAssigned={loanPromotion.is_assigned}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

const LoanPromotionForm: FC<{
  loanId: string
  promotionId: number
  isAssigned: boolean
  onSuccess: () => void
  onError: () => void
}> = ({ loanId, promotionId, isAssigned, onSuccess, onError }) => {
  const { t } = useTranslation()

  const { data: promotion, isLoading: isPromotionLoading } =
    useGetPromotionQuery(promotionId, {
      skip: promotionId == null
    })

  const [
    setLoanPromotionAsActive,
    { isLoading: isUpdating, isSuccess: isUpdated, isError: isUpdateError }
  ] = useSetLoanPromotionAsActiveMutation()

  useEffect(() => {
    if (isUpdated) onSuccess()
  }, [isUpdated, onSuccess])

  useEffect(() => {
    if (isUpdateError) onError()
  }, [isUpdateError, onError])

  return (
    <>
      {isPromotionLoading && <Loader />}
      {promotion &&
        Object.entries(promotion)
          .filter(([key]) => key !== 'id')
          .map(([key, value]) => (
            <Col key={key}>
              <FormGroup className='mb-3'>
                <Label htmlFor={key}>{t(key)}</Label>
                <Input
                  type='text'
                  className='form-control form-control-sm'
                  value={t(`${value}`)}
                  disabled
                />
              </FormGroup>
            </Col>
          ))}

      <ConfirmButton
        disabled={isPromotionLoading || isUpdating || isAssigned}
        onConfirm={() =>
          setLoanPromotionAsActive({
            loanId,
            promotionId
          })
        }
      >
        {isAssigned
          ? t('Promotion has already been applied')
          : t('Add promotion')}
      </ConfirmButton>
    </>
  )
}
