import { FC, useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import Table4Columns from '../Common/EntitiesView/Table4Columns'

// 1. Задолженность по основному долгу	 (ОД)
// 2. Задолженность по пени
// 3. Задолженность по процентам
// 4. Задолженность по Членским взносам (пока оставляем пустым)

// Названия для полей:
// kpk_fee - ЧВ в день
// kpk_extension_fee - ЧВ в день при продлении
// kpk_pending_fee - ЧВ в день при отсутствии кредита

interface LoanTableProps {
  hasData?: boolean
  entity: any
}

const columnsDescriptionHead = [
  ['org_origin', 'product_type'],
  ['org_owner', 'product_code'],
  ['org_collect', 'product_name']
]

const columnsDescriptionMain = [
  ['status', 'issue_date'],
  ['short_name', 'repayment_date'],
  ['created_at', 'closing_date'],
  ['amount_debt', 'interest'],
  ['term', 'dpd'],
  ['kpk_fee', 'interest_frozen_end_date'],
  ['kpk_pending_fee', 'penalties_frozen_end_date'],
  ['penalty_pending', 'prolongation_count'],
  ['extension_available', ''],
  ['accrued_principal', 'paid_principal'],
  ['writeoff_principal', 'debt_principal']
]

const fieldsDescriptionMain = {
  created_at: { fieldName: 'created_at', isDate: true },
  repayment_date: { fieldName: 'repayment_date', isDate: true },
  issue_date: { fieldName: 'issue_date', isDate: true },
  closing_date: { fieldName: 'closing_date', isDate: true },
  amount_debt: { isAmount: true },
  interest: { label: 'loan_interest', isAmount: true, decimals: 5 },
  term: { label: 'approved_period' },
  penalty_pending: {
    label: 'penalty_pending',
    isAmount: true,
    decimals: 5
  }
}

const columnsDescriptionOperations = [
  ['accrued_principal', 'accrued_interest'],
  ['accrued_penalty', 'accrued_overpayment'],
  ['debt_principal', 'debt_interest'],
  ['debt_penalty', 'debt_overpayment'],
  ['paid_principal', 'paid_interest'],
  ['paid_penalty', 'paid_overpayment'],
  ['writeoff_principal', 'writeoff_interest'],
  ['writeoff_penalty', 'writeoff_overpayment']
]

export const LoanTableKPK: FC<LoanTableProps> = ({ entity }) => {
  const [operationsList, setOperationsList] = useState({})

  useEffect(() => {
    const operationTypes = ['Principal', 'Interest', 'Penalty', 'Overpayment']
    const categories = ['accrued', 'debt', 'paid', 'writeoff']

    const initialResult = categories.reduce((acc, category) => {
      operationTypes.forEach((type) => {
        //@ts-ignore
        acc[`${category}_${type.toLowerCase()}`] = 0
      })
      return acc
    }, {})

    const result = { ...initialResult }

    categories.forEach((category) => {
      entity?.operations?.[category]?.forEach((value: any) => {
        const key = `${category}_${value.operation_type.toLowerCase()}`
        //@ts-ignore
        if (result[key] !== undefined) {
          //проверка нужна т.к. строка 64 (result[key] = 0 => js думает что 0 = false)
          //@ts-ignore
          result[key] = value.amount
        }
      })
    })
    setOperationsList(result)
  }, [entity])

  if (!entity) return <Container fluid>Нет данных</Container>

  // Principal - од
  // Interest - проценты
  // Penalty - штрафы
  // Overpayment - переплата
  // =======
  // accurued начислено
  // debt долг
  // paid оплачено
  // writeoff списано

  return (
    <>
      <Container fluid>
        <h6>Данные по компании / продукту</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionHead}
          fieldsDescription={[]}
          entity={{
            ...entity
          }}
        />
      </Container>
      <Container className='mt-3' fluid>
        <h6>Данные по займу</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionMain}
          fieldsDescription={fieldsDescriptionMain}
          entity={{
            ...entity
          }}
        />
      </Container>
      <Container className='mt-3' fluid>
        <h6>Операции</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionOperations}
          fieldsDescription={[]}
          entity={{
            ...operationsList
          }}
        />
      </Container>
    </>
  )
}
